@import '../../styles/customMediaQueries.css';

.root {
  display: inline-block;
  margin-right: 20px;
}

.shareList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.shareListItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;

  @media (--viewportSmall) {
    width: calc(50% - 8px);
  }

  & button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 22px;

    border: none;
    box-shadow: none;
    cursor: pointer;
  }
}

.shareListItemText {
  padding: 12px 22px;
}

.rootIcon {
  width: 32px;
  height: 32px;
  fill: none;
  flex-shrink: 0;
  margin-right: 22px;
}

.text {
  padding-left: 20px;
}

.listExistingPopupRoot > button {
  left: 0;
}

.share {
  margin-right: 10px;
}

.shareWrapper {
  cursor: pointer;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  font-size: 14px;
}

.rootForImage {
  border-radius: 15px;
  width: 100px;
}

.listingInfoWrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.listingInfo {
  margin-left: 20px;
  word-break: break-word;
}

.successText {
  color:  var(--marketplaceColor);
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.starIcon {
  margin-bottom: 3px;
}

.listEmbedExistingPopupRoot > button {
  left: 0;
}


.listEmbedExistingPopupRoot {
  padding: 50px 25px !important;

  @media (--viewportMedium) {
    flex-basis: 768px !important;
  }
}

.embedContent {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 47%;
}

.textAreaLabel {
  font-size: 16px;
}

.title {
  font-size: 22px;
}

.btn {
  width: 35%;
}

.backBtn {
  /* color: #000; */
}

.listingBlock {
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  position: relative;
  border-radius: 5px;
}

.preview {
  color: var(--colorWhite);
  text-transform: uppercase;
  top: 10px;
  left: 10px;
  position: absolute;
  font-weight: bold;
  font-size: 12px;
}

.textWrapper {
  margin-bottom: 20px;
}

.previewImg {
  display: block;
  width: 100%;
}

.listingTitle {
  padding: 10px 10px 0px;
  margin: 0;
  font-weight: bold;
}

.reviewRaiting {
  margin: 0;
  padding: 0px 10px 20px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.btnWrapper {
  padding: 10px;
}

.backBtnWrapper {
  margin-top: 40px;
}
