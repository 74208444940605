@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 0;
  margin: 0 0 20px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

.itemSelected {
  padding: 0;
  margin-bottom: 20px;
  opacity: 0.25;
  cursor: not-allowed;
  
  & span {
    pointer-events: none;
  }

  & label span svg rect {
    stroke: var(--colorGrey300) !important;
  }

  & label span svg path {
    display: none !important;
  }
}