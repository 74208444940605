@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  margin: -20px 0 70px;

  max-width: 100vw;
  overflow: hidden;

  
  @media (--viewportMedium) {
    margin: 0 0 70px;
  }
}

.itemWrapper {
  /* max-width: var(--contentMaxWidthPages); */
  /* max-width: 100vw; */
  margin: 0 auto;
  padding: 0;

  
  @media (--viewportMedium) {
    padding: 0 32px;
  }

  @media (--viewport1280) {
    max-width: 1034px;
  }

  & :global(.alice-carousel__wrapper) {
    transform: translateX(-74px);
    overflow: visible;

    @media (--viewportMedium) {
      transform: translateX(0);
      overflow: hidden;
    }
  }

  & :global(.alice-carousel) {
    position: relative;
  }

  & :global(.alice-carousel__stage-item.__active > a) {
    transform: translateX(0);
  }


  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
    padding-top: 40px;

    @media (--viewportMedium) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      padding-top: 10px;

      text-align: center;
      cursor: pointer;
    }
  }

  & :global(.alice-carousel__prev-btn) {
    left: -30px;
    padding-right: 20px;

    @media (--viewportMedium) {
      padding-right: 5px;
    }
    
    @media (--viewport1280) {
      left: -80px;
    }
  }

  & :global(.alice-carousel__next-btn) {
    right: -30px;
    padding-left: 20px;

    @media (--viewportMedium) {
      padding-left: 5px;
    }
    
    @media (--viewport1280) {
      right: -80px;
    }
  }
}
