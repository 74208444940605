.root {
}

.inputError {
  border: var(--borderErrorField);
}

.subLabel {
  composes: h5 from global;
  
  flex-shrink: 0;
  margin-top: -6px;
  margin-bottom: 0;
  font-size: 12px;
  font-family: var(--fontFamily);
  color: var(--colorGrey300);
}
