@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  margin: 10px 15px;
  
  /* max-width: 213px;
  transform: translateX(-40vw);
  
  @media screen and (max-width: 360px){
    transform: translateX(-30vw);
  } */

  @media (--viewportMedium) {
    transform: translateX(0);
    max-width: unset;
  }
  
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  padding: 12px 12px 14px;
  border: 1px solid var(--customLightGray);
  border-top: none;
  text-align: center;

}


.mainInfo {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  min-height: 44px;
}

.title {
  /* Font */
  font-size: 16px;
  line-height: 1.4;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey700);
  margin: 0 0 3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;

  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.authorInfo {
  color: var(--colorGrey700);
  opacity: 0.6;
}
