@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  /* Layout */
  display: flex;
  flex-direction: column;
}

.rootLink {

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
}

.rootForImage {
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 15px 15px 10px;
  background-color: var(--colorWhite);
  border: 1px solid #ECECEC;
}

.infoWithoutBorder {
  border: none;
}

.priceHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  /* margin-bottom: 4px; */
  margin-bottom: 0;
  color: var(--colorGrey700);
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 1;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  min-height: 44px;
}

.title {
  /* Font */
  composes: textSmall from global;
  color: var(--colorGrey700);
  margin: 0 0 12px;
    
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: var(--fontWeightRegular);
  word-break: break-word;
}

.authorInfo {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ECECEC;

  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 1;
  color: var(--customGray);
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.iconFavorites {
  position: absolute;
  right: 10px;
  top: 10px;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: var(--colorWhite);
  cursor: pointer;

  z-index: 1;
}

.iconCart {
  width: 28px;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid #ECECEC;
  background: var(--colorWhite);
}
