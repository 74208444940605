@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 0 0 32px;
  
  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.brandList {
  position: relative;
  max-width: calc(100vw - 32px);
  display: flex;
  padding: 0;
  margin: 0 0 50px;
  overflow-x: scroll;
  
  @media (--viewportMedium) {
    margin: 0 auto 80px;
    overflow-x: hidden;
    max-width: 704px;
  }
  
  @media (--viewportLarge) {
    max-width: 936px;
  }
}

.brandListItem {
  max-width: 116px;
  width: 40%;
  flex-shrink: 0;
  padding: 0;
  margin: 0 56px 0 0;
  opacity: 0.6;

  
  @media (--viewportMedium) {
    max-width: unset;
    width: 20%;
    flex-shrink: unset;
    margin: 0 auto;
  }
}
