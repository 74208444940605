@import '../../styles/customMediaQueries.css';

.root {

  & :global(.option) {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
    color: inherit;
    padding: 8px 12px;
    box-sizing: border-box;

    &:hover {
      background-color: #ffb602;
      cursor: pointer;
    }
    
    & :global(.option span) {
      display: inline-block;
    }
  }

  & :global(.level1 span) {
    padding-left: 10px;

    &::before {
      content: "-  ";
    }
  }

  & :global(.level2 span) {
    padding-left: 20px;

    &::before {
      content: "--  ";
    }
  }

  & :global(.level3 span) {
    padding-left: 30px;

    &::before {
      content: "---  ";
    }
  }

  & :global(.level4 span) {
    padding-left: 40px;

    &::before {
      content: "----  ";
    }
  }

  & :global(.level5 span) {
    padding-left: 50px;

    &::before {
      content: "-----  ";
    }
  }
}



.selectTree {

  & :global(.dropdown) {
    display: block;
    width: 100%;
  }

  & :global(.dropdown .dropdown-trigger) {
    display: block;
    width: 100%;
    padding: 6px 12px 4px 12px;
    border: 1px solid #201F2233;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @media (--viewportMedium) {
      padding: 7px 16px;
    }
  }

  & :global(.dropdown .dropdown-trigger.arrow.bottom:after) {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml, %3Csvg width='13' height='9' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M6.03 6.805c.26.26.68.26.94 0l5.335-5.333a.668.668 0 0 0-.943-.943L6.5 5.39 1.638.53a.666.666 0 1 0-.943.942L6.03 6.805z' fillRule='evenodd' /%3E%3C/svg%3E");
    background-position: center;
  }
  
  & :global(.dropdown .dropdown-trigger.arrow.top:after) {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url("data:image/svg+xml, %3Csvg width='13' height='9' viewBox='0 0 13 9' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M6.97.195a.664.664 0 0 0-.94 0L.695 5.528a.668.668 0 0 0 .943.943L6.5 1.61l4.862 4.86a.666.666 0 1 0 .943-.942L6.97.195z' fillRule='evenodd' /%3E%3C/svg%3E");
    background-position: center;
  }

  & :global(.tag-item) {
    padding: 0;
    margin: 0;
  }

  & :global(.tag-item .search) {
    padding: 0;
    border-bottom: 1px solid #201F2233;
  }

  & :global(.dropdown .dropdown-content) {
    width: 100%;
    padding: 6px 12px 4px 12px;

    @media (--viewportMedium) {
      padding: 7px 16px;
    }
  }

  & :global(.dropdown .dropdown-content ul li) {
    
  }

  & :global(.dropdown .dropdown-content ul li > label ) {
    display: flex;
    align-items: center;
  }

  & :global(.dropdown .dropdown-content ul li > label input ) {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    visibility: hidden;
    overflow: hidden;
    padding: 0;
    border: 0;
  }

  & :global(.dropdown .dropdown-content ul li > label span ) {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 19px;
      margin-right: 10px;
      border: 1px solid #A6A5A7;
      border-radius: 2px;
      background: transparent;
    }
  }

  & :global(.dropdown .dropdown-content ul li.checked > label span ) {

    &::before {
      background: #ccc;
    }
  }

  & :global(.dropdown .dropdown-content ul li .toggle ) {
    display: none;
  }

  & :global(.dropdown .dropdown-content ul li.checked .toggle ) {
    display: block;
  }

  & :global(.dropdown .tag-list .tag-item ) {

  }

  & :global(.dropdown .tag-list .tag-item .tag) {
    font-size: 14px;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--marketplaceColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

