.root {

  margin-top: 0;
  margin-bottom: 0;
  white-space: pre-line;
  padding-top: 10px;

  & p {
    margin: 0;
    line-height: 24px;
    font-size: 14px;
  }

  & p,
  & ul,
  & ol {
    margin-block-start: -10px;
    margin-block-end: -10px;
  }

  & :global(.DraftEditor-editorContainer) {
    background-color: transparent;
    border: none;

    & span {
      background-color: transparent !important;
    }
  }


  & :global(.public-DraftStyleDefault-block) {
    margin: 10px 0;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
    white-space: normal;
  }

  & a span,
  & a {
    color: var(--marketplaceColorDark)!important;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: var(--marketplaceColor)!important;
    }
  }

  & ul {
    margin: -20px 0 0;
    padding-left: 30px;
    list-style: disc;
    line-height: 1;
  }

  & ol {
    padding-left: 30px;
    margin: -20px 0 0;
    line-height: 1;
  }

  & p span,
  & a span,
  & li span {
    font-family: var(--fontFamily) !important;
  }

  & p,
  & li,
  & a {
    font-size: 16px;
  }
}
