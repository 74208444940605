.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rootWithBg {

  /* & .pageNumberList {
    background-color: var(--colorWhite);
  } */
}

.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  /* Dimensions */
  /* padding-top: 26px;
  padding-bottom: 26px; */
  padding: 0;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;

  border: 1px solid var(--customLightGray);
  background-color: var(--colorWhite);

  &:hover .arrowIcon,
  &:focus .arrowIcon {
    fill: var(--marketplaceColor);
    stroke: var(--marketplaceColor);
  }
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  /* padding-right: 33px; */
  margin-right: 16px;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  /* padding-left: 33px; */
  margin-left: 16px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--colorGrey700);
  stroke: var(--colorGrey700);

  &:hover {
    fill: var(--marketplaceColor);
    stroke: var(--marketplaceColor);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 250px;
}

.pageNumberList6Items {
  flex-basis: 300px;
}

.pageNumberList7Items {
  flex-basis: 325px;
}

.toPageLink {
  /* Dimensions */
  padding: 6px 14px;

  /* Color for svg icons */
  color: var(--customGray);
  font-weight: var(--fontWeightRegular);

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}

.currentPage {
  /* Dimensions */
  padding: 6px 14px;
  /* border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--colorBlack); */

  /* Color */
  color: var(--colorBlack);
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
